<template>
  <Breadcrumb />
  <div class="d-flex justify-content-end">
    <router-link to="New" class="btn btn-success btn-action-new-page"
      ><i class="bi bi-plus"></i>
      {{
        $t(
          "ImportBulkRecordData.NewImportBulkRecordData",
          {},
          { locale: this.$store.state.activeLang }
        )
      }}</router-link
    >
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    :pageNumberStartFrom="0"
    @onMonitoringButtonClick="onMonitoringButtonClick"
    @onFileDownload="fileDownload"
    @onBulkImportCancelButtonClick="onBulkImportCancelButtonClick"
  />
  <ImportMonitoringModal
    :isMountedRequest="isMountedRequest"
    :importBulkRecordDataRecordPublicId="clickedRecordPublicId"
  />
  <AreYouSureModal
    :modalType="areYouSureModalType"
    :warningMessage="areYouSureModalWarningMessage"
    :requestParameter="areYouSureModalRequestParameter"
    :isBrsAxios="true"
  />
</template>
<script>
import ImportMonitoringModal from "@/components/page-partials/data-import/ImportMonitoringModal";
import AreYouSureModal from "@/components/layout/AreYouSureModal.vue";
export default {
  name: "ImportBulkRecordDataList",
  components: { ImportMonitoringModal, AreYouSureModal },
  data() {
    return {
      areYouSureModalType: "",
      areYouSureModalRequestParameter: "",
      areYouSureModalWarningMessage: "",
      isMountedRequest: false,
      clickedRecordPublicId: null,
      gridSettings: {
        action: "ImportBulkRecordDataList",
        requestUrl: "Lcdp-BulkDataImportList",

        allowDragAndDrop: false,
        allowPaging: true,
        allowDeleting: false,

        isGatewayRequest: true,

        autoOrderProcess: true,
        buttons: [
          {
            name: "monitoring",
            cssClass: "btn-primary",
            iconClass: "bi-display",
            routeButton: false,
            emitMethodName: "onMonitoringButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalImportBulkRecordDataMonitoring",
            },
          },
          {
            name: "cancel",
            cssClass: "btn-danger btn-process-cancel",
            iconClass: "bi bi-x",
            routeButton: false,
            emitMethodName: "onBulkImportCancelButtonClick",
            attributes: {
              "data-bs-toggle": "modal",
              "data-bs-target": "#modalAreYouSure",
            },
            iconAttributes: {
              "data-bs-toggle": "tooltip",
              "data-bs-placement": "top",
              title: this.$t(
                "Components.AreYouSureModal.BulkImportCancelTitle",
                {},
                { locale: this.$store.state.activeLang }
              ),
            },
          },
        ],
      },
      gridColumns: [
        {
          text: this.$t(
            "BaseModelFields.CustomObject",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "customObjectName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.RecordUploadType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "processTypeName",
          type: "string",
          visible: true,
          width: 0,
          template: "importBulkRecordDataProcessTypeTemplate",
          format: "",
        },
        {
          text: this.$t(
            "BaseModelFields.Status",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "status",
          type: "string",
          visible: true,
          width: 0,
          template: "importBulkRecordDataStatusTemplate",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.StartAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "startAt",
          type: "datetime",
          visible: true,
          width: 0,
          template: "",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.CompletedAt",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "completedAt",
          type: "datetime",
          visible: true,
          width: 0,
          template: "",
          format: "",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.UploadedFile",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "",
          type: "string",
          visible: true,
          width: 0,
          template: "importBulkRecordDataUploadedFileTemplate",
          format: "",
          textAlign: "center",
        },
        {
          text: this.$t(
            "ImportBulkRecordData.ProcessedFile",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "",
          type: "string",
          visible: true,
          width: 0,
          template: "importBulkRecordDataProcessedFileTemplate",
          format: "",
          textAlign: "center",
        },
      ],
    };
  },
  methods: {
    onMonitoringButtonClick(rowData) {
      this.isMountedRequest = !this.isMountedRequest;
      this.clickedRecordPublicId = rowData.publicId;
    },
    fileDownload(parameters) {
      this.$prodGatewayAxios
        .get(
          `/Lcdp-BulkDataImportGetDocument?id=${parameters.recordId}&type=${parameters.type}`,
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: String.format("application/octet-stream"),
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = parameters.name;
          link.click();
          URL.revokeObjectURL(link.href);
        });
    },
    onBulkImportCancelButtonClick(rowData) {
      this.areYouSureModalType = "MonitoringCancelJob";
      this.areYouSureModalRequestParameter = rowData.publicId;
      this.areYouSureModalWarningMessage = this.$t(
        "Components.AreYouSureModal.BulkImportCancelWarning",
        {},
        { locale: this.$store.state.activeLang }
      );
    },
  },
};
</script>
