<template>
  <div
    class="modal fade"
    :id="`modalImportBulkRecordDataMonitoring`"
    tabindex="-1"
    :aria-labelledby="`modalImportBulkRecordDataMonitoringLabel`"
    aria-hidden="true"
  >
    <div class="modal-dialog" style="width: 90%; max-width: 90% !important">
      <div class="modal-content">
        <div class="modal-header p-4">
          <h5
            class="modal-title"
            :id="`modalImportBulkRecordDataMonitoringLabel`"
          >
            <i class="bi bi-display"></i>
            {{
              $t(
                "ImportBulkRecordData.Monitoring",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <template v-if="data && data.isOk">
            <div class="row mb-4">
              <div class="col border-bottom">
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.StateName",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br /><b
                  :class="{
                    'text-danger':
                      data.stateName == 'Failed' || data.stateName == 'Deleted',
                    'text-success':
                      data.stateName == 'Completed' ||
                      data.stateName == 'CompletedWithErrors' ||
                      data.stateName == 'Succeeded',
                    'text-warning': data.stateName == 'Processing',
                    'text-info': data.stateName == 'Waiting',
                  }"
                >
                  {{
                    $t(
                      "ImportBulkRecordData." + data.stateName,
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</b
                >
              </div>
              <div class="col border-bottom">
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.StateReason",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br /><b>{{
                  String.isNullOrWhiteSpace(data.stateReason)
                    ? "-"
                    : data.stateReason
                }}</b>
              </div>
            </div>
            <div class="row mb-4">
              <div class="col border-bottom">
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.CreatedAt",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br /><b>{{
                  dateTime(data.createdAt, "DD.MM.YYYY HH:mm:ss")
                }}</b>
              </div>
              <div class="col border-bottom">
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.ExpireAt",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br /><b>{{
                  String.isNullOrWhiteSpace(data.expireAt)
                    ? "-"
                    : dateTime(data.expireAt, "DD.MM.YYYY HH:mm:ss")
                }}</b>
              </div>
              <div class="col border-bottom">
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.FetchedAt",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br /><b>{{
                  String.isNullOrWhiteSpace(data.fetchedAt)
                    ? "-"
                    : dateTime(data.fetchedAt, "DD.MM.YYYY HH:mm:ss")
                }}</b>
              </div>
            </div>
            <div class="row mb-4">
              <div
                class="col"
                :class="{ 'border-bottom': stateData.columns.length == 0 }"
              >
                <label>
                  {{
                    $t(
                      "ImportBulkRecordData.StateData",
                      {},
                      { locale: this.$store.state.activeLang }
                    )
                  }}</label
                ><br />
                <template v-if="stateData.columns.length > 0">
                  <div class="table-responsive">
                    <table class="table table-hover table-bordered mb-0">
                      <thead class="table-primary">
                        <tr>
                          <td v-for="(column, i) in stateData.columns" :key="i">
                            {{
                              $t(
                                "BaseModelFields." + column,
                                {},
                                { locale: this.$store.state.activeLang }
                              )
                            }}
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td v-for="(item, j) in stateData.columns" :key="j">
                            <span
                              v-if="stateDataDateFormulaNames.includes(item)"
                              >{{ dateTime(stateData.rows[j]) }}</span
                            >
                            <span v-else>{{ stateData.rows[j] }}</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
                <template v-else>
                  <b>-</b>
                </template>
              </div>
            </div>
            <div class="row">
              <div class="col-md-3">
                <div class="counter-box border">
                  <span class="counter">{{ data.totalCount }}</span>
                  <p>
                    {{
                      $t(
                        "BaseModelFields.Total",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="counter-box bg-success">
                  <span class="counter text-white">{{
                    data.succeededCount
                  }}</span>
                  <p class="text-white">
                    {{
                      $t(
                        "BaseModelFields.Successful",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="counter-box bg-danger">
                  <span class="counter text-white">{{ data.failedCount }}</span>
                  <p class="text-white">
                    {{
                      $t(
                        "BaseModelFields.Failed",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </div>
              <div class="col-md-3">
                <div class="counter-box bg-warning">
                  <span class="counter text-white">{{
                    data.totalCount - data.processedCount
                  }}</span>
                  <p class="text-white">
                    {{
                      $t(
                        "BaseModelFields.Waiting",
                        {},
                        { locale: this.$store.state.activeLang }
                      )
                    }}
                  </p>
                </div>
              </div>
            </div>
          </template>
          <template v-else-if="data && !data.isOk">
            <div class="alert alert-warning mb-0">
              {{ data.msg }}
            </div>
          </template>
          <template v-else-if="!data">
            <div class="alert alert-warning mb-0">
              {{
                $t(
                  "Buttons.PleaseWait",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </template>
        </div>
        <div class="modal-footer p-4">
          <button
            type="button"
            class="btn btn-success"
            @click="getBulkImportJobDetail"
            v-if="data"
          >
            <i class="bi bi-arrow-clockwise"></i>
            {{
              $t(
                "Buttons.Refresh",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button type="button" class="btn btn-danger" data-bs-dismiss="modal">
            <i class="bi bi-x"></i>
            {{
              $t("Buttons.Close", {}, { locale: this.$store.state.activeLang })
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  name: "ImportMonitoringModal",
  props: {
    importBulkRecordDataRecordPublicId: {
      type: String,
      default: null,
    },
    isMountedRequest: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: null,
      stateData: {
        columns: [],
        rows: [],
      },
      stateDataDateFormulaNames: ["FailedAt", "DeletedAt", "SucceededAt"],
    };
  },
  components: {},
  watch: {
    isMountedRequest: {
      deep: true,
      handler() {
        if (
          !String.isNullOrWhiteSpace(this.importBulkRecordDataRecordPublicId)
        ) {
          this.getBulkImportJobDetail();
        }
      },
    },
  },
  methods: {
    dateTime(value, mFormat = "YYYY-MM-DDTHH:mm:ss") {
      return this.$moment(value, mFormat).format(
        this.$store.getters._dateTimeFormat.toUpperCase()
      );
    },
    animationReady() {
      $(".counter").each(function () {
        $(this)
          .prop("Counter", 0)
          .animate(
            {
              Counter: $(this).text(),
            },
            {
              duration: 2000,
              easing: "swing",
              step: function (now) {
                $(this).text(Math.ceil(now));
              },
            }
          );
      });
    },
    createStateDataTable() {
      if (
        !this.data ||
        (this.data && String.isNullOrWhiteSpace(this.data.stateData))
      )
        return;

      var parsedStateData = JSON.parse(this.data.stateData),
        self = this;

      Object.keys(parsedStateData).forEach(function (key) {
        self.stateData.columns.push(key);
        self.stateData.rows.push(parsedStateData[key]);
      });
    },
    getBulkImportJobDetail() {
      this.data = null;
      this.stateData = {
        columns: [],
        rows: [],
      };

      this.$prodGatewayAxios
        .get(
          String.format(
            "Brs-MonitoringGetBulkImportJobDetail?bulkDataImportPublicId={0}",
            this.importBulkRecordDataRecordPublicId
          )
        )
        .then((response) => {
          this.data = response.data;
          this.createStateDataTable();
        })
        .catch(function (error) {
          //alert(error);
        })
        .finally(() => {
          this.animationReady();
        });
    },
  },
};
</script>
